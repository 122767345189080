import type { AppProps } from 'next/app';
import { wrapper } from '@shapegames/core/dist/src/packages/store/store';
import { sportradarPreset } from '@shapegames/quick-draw-ui/lib/theme';
import { ThemeProvider } from 'styled-components';

// CSS
import '@shapegames/quick-draw-ui/lib/assets/icons/style.css';
import '@shapegames/core/dist/src/packages/app/styles/animations.css';

function App({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider theme={sportradarPreset as any}>
      <Component {...pageProps} />
    </ThemeProvider>
  );
}

export default wrapper.withRedux(App);
